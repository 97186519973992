import React from 'react';
import Layout from '../layouts/Layout';
import I18n from '../utils/I18n';
//import Translate from '../utils/Translate';

const HomeTemplate = data => {
  const {pageContext: lang} = data;
  //const translate = new Translate({lang, texts});
  const i18n = new I18n(lang);
  //const text = texts[lang][0].node;

  return (
    <Layout>
      <div>
        <tm-page class="theme--salesforce">

          <map name="websitemap">
            <area shape="rect" coords="950,110,1070,140" alt="Website" href="https://trailhead.salesforce.com" />
          </map>

          <div className="slds-is-relative">
            <img src="https://developer.salesforce.com/resources2/trailheadVirtualBootcamp/vb-banner.png" />
            <a className="slds-is-absolute" href="https://trailheadacademy.salesforce.com/overview" style={{top: '63%', left: '50%', width: '6.2%', height: '18%'}}></a>
          </div>

          
          
          <tm-hero-a
            background="light"
            cta-href={i18n.text('component1_ctaLink')}
            cta-label={i18n.text('component1_ctaText')}
            cta-target="_blank"
            img-align="bottom"
            img-src="https://developer.salesforce.com/resources2/trailheadVirtualBootcamp/TVB-LandPage-Graphics_Hero image.gif"
            subtitle={i18n.text('component1_description')}
            title={i18n.text('component1_title')}>
          </tm-hero-a>

          <tm-section-a background="white">
            <tm-feature-grid-a>
              <tm-grid-item
                alt=""
                description={i18n.text('component2_description')}
                img-src="https://developer.salesforce.com/resources2/trailheadVirtualBootcamp/TVB-LandPage-Graphics_Learn-SF-Exp.gif"
                title={i18n.text('component2_title')}>
              </tm-grid-item>
              <tm-grid-item
                alt=""
                description={i18n.text('component3_description')}
                img-src="https://developer.salesforce.com/resources2/trailheadVirtualBootcamp/TVB-LandPage-Graphics_AchieveLearningMilestones.png"
                title={i18n.text('component3_title')}>
              </tm-grid-item>
              <tm-grid-item
                alt=""
                description={i18n.text('component4_description')}
                img-src="https://developer.salesforce.com/resources2/trailheadVirtualBootcamp/TVB-LandPage-Graphics_SkillUp-Anytime.png"
                title={i18n.text('component4_title')}>
              </tm-grid-item>
              <tm-grid-item
                alt=""
                description={i18n.text('component5_description')}
                img-src="https://developer.salesforce.com/resources2/trailheadVirtualBootcamp/section4.gif"
                title={i18n.text('component5_title')}>
              </tm-grid-item>
            </tm-feature-grid-a>
          </tm-section-a>


          <div className="background-dark">
            <div style={{maxWidth: '500px'}} className="slds-container_center">
              <tm-end-cap-a 
                background="dark"
                title={i18n.text('endcap_section_text')}
                cta-label={i18n.text('endcap_section_cta')}
                cta-href="https://trailheadacademy.salesforce.com/more-classes?skillLevel=sf.sl12" />
            </div>
          </div>

        </tm-page>
       
        <p>
          <th-element-loader
            element="tm-page"
            style={{height: "100vh"}}>
          </th-element-loader>
        </p>

      </div>
    </Layout>
  );
};

export default HomeTemplate;
